<template>
    <div>
        <v-row>
            <v-col cols="12">
                <!-- Breadcrumbs and help -->
                <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="0"></breadcrumbs-with-help>
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <v-col cols="12" md="9">
                    <!-- Borderel details -->
                    <v-card>
                        <v-card-text class="pa-6" flat>
                            <div class="info-box">
                                <div class="pa-6">
                                    <div class="d-flex align-end flex-column">
                                        <div class="d-flex align-center borderel-logo mb-3">
                                            <v-avatar alt="theater_logo" :image="logo"></v-avatar>
                                        </div>
                                    </div>
                                    <v-divider></v-divider>
                                </div>

                                <div class="d-flex flex-wrap justify-space-between flex-column flex-sm-row px-6 mb-5">
                                    <!-- Left side -->
                                    <div>
                                        <div>
                                            <h4>{{ borderel.providerName }}</h4>
                                            <h4>{{ borderel.providerAddress }}</h4>
                                            <h4>{{ borderel.providerPostCode }}, {{ borderel.providerCity }}</h4>
                                        </div>
                                    </div>
                                    <!-- Right side -->
                                    <div>
                                        <div class="text-right">
                                            <h4>{{ borderel.theaterName }}</h4>
                                            <h4>{{ borderel.theaterAddress }}</h4>
                                            <h4>{{ borderel.theaterPostCode }}, {{ borderel.theaterCity }}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="pa-6">
                                        <div>
                                            <h2>Borderel</h2><br />
                                            <h4>Voorstelling: {{ borderel.productionName }}</h4>
                                            <h4>Datum: {{ borderel.performanceDate }}</h4>
                                        </div>
                                    </div>
                                    <div class="px-6 py-2">
                                        <div>
                                            <p class="text-primary">
                                                Deal: {{ borderel.contractType }}
                                            </p>
                                            <p class="text-primary">
                                                <!-- Partage bespeler: {{ borderel.contractType }} -->
                                            </p>
                                        </div>
                                    </div>
                                    <div class="px-6">
                                        <v-divider></v-divider>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="d-flex flex-wrap justify-space-between flex-column flex-sm-row gap-6 info-box rounded pa-6 mb-6">
                                <!-- Left side -->
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>Bruto recette</strong>
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            af: theatertoeslag
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            af: consumptietoeslag
                                        </p>
                                    </div>
                                </div>
                                <!-- Center -->
                                <div>
                                    <div class="pa-5"></div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>({{ totalNumberOfTickets }} x {{ borderel.totalSurcharge
                                                }})</strong>
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>({{ totalNumberOfTickets }} x {{ borderel.consumptionSurcharge
                                                }})</strong>
                                        </p>
                                    </div>
                                </div>
                                <!-- Right side -->
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.totalRevenue }}</strong>
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.theaterFeeInVat }}</strong>
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.consumptionFeeInVat }}</strong>
                                        </p>
                                    </div>
                                </div>
                                <v-divider></v-divider>
                                <br /> <br />
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>Recette</strong>
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                        </p>
                                    </div>
                                </div>
                                <v-divider></v-divider>
                                <!-- Left side -->
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            af: btw
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            af: auteursrechten
                                        </p>
                                    </div>
                                </div>

                                <!-- Center -->
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>9%</strong>
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>{{ borderel.contactCopyrightPercentage + '%' }}</strong>
                                        </p>
                                    </div>
                                </div>

                                <!-- Right side -->
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.grossRevenueVat }}</strong>
                                        </p>
                                    </div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.copyrightTotalExVat }}</strong>
                                        </p>
                                    </div>
                                </div>

                                <v-divider></v-divider>
                                <br /> <br />
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>Netto Recette (grondslag)</strong>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.netRevenueExVat }}</strong>
                                        </p>
                                    </div>
                                </div>
                                <v-divider></v-divider>
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            aandeel aanbieder
                                        </p>
                                    </div>

                                    <div class="pa-2">
                                        <p class="text-primary">
                                            btw
                                        </p>
                                    </div>

                                    <div class="pa-2">
                                        <p class="text-primary">
                                            auteursrechten
                                        </p>
                                    </div>

                                    <div class="pa-2">
                                        <p class="text-primary">
                                            btw over auteursrechten
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.providerRevenueExVat }}</strong>
                                        </p>
                                    </div>

                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.providerRevenueVat }}</strong>
                                        </p>
                                    </div>

                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.copyrightTotalExVat }}</strong>
                                        </p>
                                    </div>

                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.copyrightTotalVat }}</strong>
                                        </p>
                                    </div>
                                </div>
                                <v-divider></v-divider>
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary">
                                            <strong>Totaal te factureren</strong>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div class="pa-2">
                                        <p class="text-primary text-right">
                                            <strong>{{ borderel.calculation.providerTotalInVat }}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="info-box" v-if="borderel.instructions">
                                <p class="pa-6 mb-0">
                                    <span class="text-high-emphasis font-weight-medium me-1">
                                        <strong>Opmerking:</strong>
                                    </span>
                                    <span>{{ borderel.instructions }}</span>
                                </p>
                            </div>

                            <div class="py-6">
                                <v-table class="border mb-6 custom-table">
                                    <thead>
                                        <tr>
                                            <th class="text-left" @click="sortTable('type')">Tickettypes <v-icon
                                                    icon="mdi mdi-sort" size="small"></v-icon></th>
                                            <th class="text-right" @click="sortTable('originalCount')">Hoeveelheid <v-icon
                                                    icon="mdi mdi-sort" size="small"></v-icon></th>
                                            <th class="text-right" @click="sortTable('pricePerTicket')">Prijs per ticket
                                                <v-icon icon="mdi mdi-sort" size="small"></v-icon></th>
                                            <th class="text-right" @click="sortTable('originalPrice')">Totaal <v-icon
                                                    icon="mdi mdi-sort" size="small"></v-icon></th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-base">
                                        <tr v-for="(group, index) in borderel.groups" :key="index">
                                            <td>{{ group.type }}</td>
                                            <td class="text-right">{{ group.originalCount }}</td>
                                            <td class="text-right">{{ getEuroCurrencyView(group.pricePerTicket) }}</td>
                                            <td class="text-right">{{ getEuroCurrencyView(group.originalPrice) }}</td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="3">
                    <v-card>
                        <v-card-text>
                            <div class="d-flex flex-column mb-6">
                                <v-btn color="primary" prepend-icon="mdi-file-move" rounded depressed
                                    @click="downloadPDF" class="font-weight-bold text-lowercase mr-2">Download PDF
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import ApiService from '../../services/api.service';
import router from '../../router/router.js';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';

import functions from '../../services/functions.service.js';

export default {
    name: 'BorderelDetail',
    props: ['contractId'],
    components: {
        BreadcrumbsWithHelp
    },
    data() {
        return {
            borderel: {
                providerName: '',
                productionName: '',
                contractDate: '',
                contractType: '',
                theaterSurcharge: 0,
                consumptionSurcharge: 0,
                groups: [],
                calculation: {},
            },
            breadcrumbs: [
                {
                    text: 'financiële afspraken',
                    disabled: false,
                    href: '/financiele-afspraken',
                    bold: false,
                },
                {
                    text: 'borderel',
                    disabled: false,
                    href: '',
                    bold: true,
                },
            ],
            sortAsc: true,
        }
    },
    mounted() {
        this.getBorderel();
    },
    methods: {
        getBorderel: function () {
            ApiService.get('Borderel/GetBorderel', {
                contractId: this.contractId
            })
                .then((response) => {
                    if (response.data.success) {
                        this.borderel = response.data.result;

                        this.borderel.performanceDate = functions.getDateTimeView(this.borderel.performanceDate);
                        this.borderel.totalRevenue = functions.getEuroSignView(this.borderel.totalRevenue);
                        this.borderel.theaterSurcharge = functions.getEuroSignView(this.borderel.theaterSurcharge);
                        this.borderel.totalSurcharge = functions.getEuroSignView(this.borderel.totalSurcharge);
                        this.borderel.consumptionSurcharge = functions.getEuroSignView(this.borderel.consumptionSurcharge);
                        this.borderel.calculation.providerRevenueExVat = functions.getEuroSignView(this.borderel.calculation.providerRevenueExVat);
                        this.borderel.calculation.theaterFeeInVat = functions.getEuroSignView(this.borderel.calculation.theaterFeeInVat);
                        this.borderel.calculation.consumptionFeeInVat = functions.getEuroSignView(this.borderel.calculation.consumptionFeeInVat);
                        this.borderel.calculation.grossRevenueVat = functions.getEuroSignView(this.borderel.calculation.grossRevenueVat);
                        this.borderel.calculation.copyrightTotalExVat = functions.getEuroSignView(this.borderel.calculation.copyrightTotalExVat);
                        this.borderel.calculation.copyrightTotalVat = functions.getEuroSignView(this.borderel.calculation.copyrightTotalVat);
                        this.borderel.calculation.netRevenueExVat = functions.getEuroSignView(this.borderel.calculation.netRevenueExVat);
                        this.borderel.calculation.providerRevenueVat = functions.getEuroSignView(this.borderel.calculation.providerRevenueVat);
                        this.borderel.calculation.providerTotalInVat = functions.getEuroSignView(this.borderel.calculation.providerTotalInVat);
                    } else {
                        router.back();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    router.back();
                });
        },
        getEuroCurrencyView(item) {
            return functions.getEuroSignView(item);
        },
        downloadPDF() {
            ApiService.getFile('Borderel/GetBorderelPdf', {
                contractId: this.contractId
            })
                .then((response) => {
                    var contentType = response.headers['content-type'];
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                    var fileLink = document.createElement('a');

                    var docTitle = 'borderel-' + this.contractId + '.pdf';

                    fileLink.href = fileURL;
                    fileLink.download = docTitle;
                    fileLink.click();
                    URL.revokeObjectURL(fileLink.href);
                })
                // eslint-disable-next-line
                .catch((error) => {
                    console.log(error);
                });
        },
        sortTable(field = 'type') {
            this.sortAsc = !this.sortAsc; // Toggle sorting order
            this.borderel.groups.sort((a, b) => {
                if (this.sortAsc) {
                    if (typeof a[field] === 'string') {
                        return a[field].localeCompare(b[field]); // String comparison
                    } else {
                        return a[field] - b[field]; // Numeric comparison
                    }
                } else {
                    if (typeof a[field] === 'string') {
                        return b[field].localeCompare(a[field]); // String comparison
                    } else {
                        return b[field] - a[field]; // Numeric comparison
                    }
                }
            });
        },
    },
    computed: {
        logo() {
            if (!this.borderel.theaterLogo) {
                return '/images/dip-logo.png'
            }
            return `data:${this.borderel.theaterLogo.contentType};base64,${this.borderel.theaterLogo.data}`;
        },
        totalNumberOfTickets() {
            return this.borderel.groups.reduce((acc, group) => acc + group.count, 0);
        }
    }
}
</script>

<style scoped>
.borderel-logo {
    display: flex;
    align-items: center;
    column-gap: 0.75rem;

    .app-logo-title {
        font-size: 1.375rem;
        font-weight: 700;
        letter-spacing: 0.25px;
        line-height: 1.5rem;
        text-transform: capitalize;
    }
}

h2,
h4 {
    color: rgb(var(--v-theme-primary));
}

.info-box {
    background-color: rgb(var(--v-theme-accent));
    box-shadow: none;
}

.custom-table thead th {
    background-color: rgb(var(--v-theme-accent)) !important;
    color: rgb(var(--v-theme-primary)) !important;
    border-color: rgb(var(--v-theme-info)) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    white-space: nowrap;
    padding: 10px;
    text-align: left;
}

.custom-table tbody td {
    border-color: rgb(var(--v-theme-info)) !important;
    color: rgb(var(--v-theme-primary)) !important;
    border-bottom-width: 2px !important;
    padding: 8px 16px;
    font-size: 16px !important;
}
</style>