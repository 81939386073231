<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row v-if="rights.edit && user.organisationTypeId != 2" flex align="center" justify="space-between"
          class="pa-2 mb-4">
          <v-col cols="12" class="py-3 add-row d-flex justify-sm-space-between align-center">
            <span class="d-none d-sm-flex">een nieuwe deal aanmaken?</span>
            <v-btn color="primary" rounded depressed class="font-weight-bold text-lowercase"
              :to="{ name: 'ContractsAdd' }">
              nieuwe deal aanmaken
              <v-icon small right>mdi-file-document</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row flex justify="center" align="center" class="pb-2" no-gutters>
          <!-- Breadcrumbs and help -->
          <breadcrumbs-with-help :breadcrumbs="breadcrumbs" :help-id="helpId"></breadcrumbs-with-help>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-lg-flex">
              <filter-period :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason" @changePeriod="filterPeriod = $event"></filter-period>

              <v-text-field v-if="user.organisationTypeId != 2" v-model="tableContractsFilter.theater"
                label="zoek op afnemer" hide-details variant="outlined" density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-text-field>
              <v-text-field v-if="user.organisationTypeId != 3" v-model="tableContractsFilter.producer"
                label="aanbieder zoeken" hide-details variant="outlined" density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-text-field>
              <v-text-field v-model="tableContractsFilter.production" label="productie zoeken" hide-details
                variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>

              <v-autocomplete :items="statuses" v-model="tableContractsFilter.status" item-text="text"
                label="status selecteren" clearable hide-details variant="outlined" density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
              <v-autocomplete :items="types" v-model="tableContractsFilter.type" label="type afspraak selecteren"
                clearable hide-details variant="outlined" density="compact" bg-color="#fff"
                class="ma-1 input-sm"></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-lg-none">
              <v-dialog v-model="tableContractsFilterDialog" width="500" fullscreen>
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn class="font-weight-bold text-lowercase" color="success" rounded dark
                    v-bind="activatorProps">filter overzicht
                    <v-icon small right>mdi-filter</v-icon></v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline dark">
                    <h5 class="text-primary">filter overzicht</h5>
                  </v-card-title>

                  <v-card-text>
                    <filter-period :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                      selectedDefault="thisSeason" @changePeriod="filterPeriod = $event"></filter-period>

                    <v-text-field v-if="user.organisationTypeId != 2" v-model="tableContractsFilter.theater"
                      label="zoek op afnemer" hide-details variant="outlined" density="compact" bg-color="#fff"
                      class="ma-1 input-sm"></v-text-field>
                    <v-text-field v-if="user.organisationTypeId != 3" v-model="tableContractsFilter.producer"
                      label="aanbieder zoeken" hide-details variant="outlined" density="compact" bg-color="#fff"
                      class="ma-1 input-sm"></v-text-field>
                    <v-text-field v-model="tableContractsFilter.production" label="productie zoeken" hide-details
                      variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-text-field>

                    <v-autocomplete :items="statuses" item-text="text" label="status selecteren" clearable hide-details
                      variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                    <v-autocomplete :items="types" label="type afspraak selecteren" clearable hide-details
                      variant="outlined" density="compact" bg-color="#fff" class="ma-1 input-sm"></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn class="font-weight-bold text-lowercase mb-5" color="success" depressed
                      @click="tableContractsFilterDialog = false">filter overzicht
                      <v-icon small right>mdi-filter</v-icon></v-btn>
                    <v-btn class="font-weight-bold text-lowercase" color="error" outlined dark
                      @click="clearFilterContracts()">reset
                      filter
                      <v-icon small right>mdi-close</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table :headers="tableContractsHeadersFiltered" 
          :items="tableContractsFiltered" 
          :mobile-breakpoint="0"
          :loading="tableContractsLoading" 
          :page.sync="tableContractsPaging.page"
          :items-per-page="tableContractsPaging.itemsPerPage" 
          hide-default-footer
          @update:current-items="tableContractsPaging.pageCount = Math.ceil(tableContractsFiltered.length / tableContractsPaging.itemsPerPage)"
          @update:items-per-page="tableContractsPaging.pageCount = Math.ceil(tableContractsFiltered.length / tableContractsPaging.itemsPerPage)"
          @click:row="(_, x) => goToDetail(x.item)" 
          class="table-action row-is-clickable" 
          fixed-header
          :height="tableContractsFiltered.length > 10 ? '55vh' : ''">
          <template v-slot:[`item.modified`]="{ item }">{{ item.modifiedView }}</template>
          <template v-slot:[`item.firstPerformance`]="{ item }">{{ item.firstPerformanceView }}</template>

          <template v-slot:[`item.totalExVat`]="{ item }">
            <v-chip v-if="item.totalExVat > 0" color="success" small label><strong>{{ getEuroSign(item.totalExVat)
                }}</strong></v-chip><v-chip v-else-if="item.totalExVat < 0" color="error" small label><strong>{{
                  getEuroSign(item.totalExVat) }}</strong></v-chip>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <v-chip v-if="item.state == 'goedgekeurd'" color="success" small label><strong>{{ item.state
                }}</strong></v-chip>
            <v-chip v-else-if="item.state == 'voorstel'" color="accent_2" class="text-primary" small label><strong>{{
              item.state }}</strong></v-chip>
            <v-chip v-else-if="item.state == 'concept'" class="text-primary" small label><strong>{{ item.state
                }}</strong></v-chip>
            <v-chip v-else-if="item.state == 'aangepast voorstel'" color="warning" class="text-primary" small
              label><strong>{{ item.state }}</strong></v-chip>
            <v-chip v-else-if="item.state == 'herzien'" color="warning" class="text-primary" small label><strong>{{
              item.state }}</strong></v-chip>
            <v-chip v-else-if="item.state == 'afgekeurd'" color="error" small label><strong>{{ item.state
                }}</strong></v-chip>
          </template>

          <template v-slot:[`item.fixedAmount`]="{ item }">{{ item.fixedAmountView }}</template>
          <template v-slot:[`item.percentage`]="{ item }">{{ item.percentageView }}</template>

          <template v-slot:[`item.action`]="{ item }">
            <!-- Edit -->
            <v-btn v-if="
              rights.edit && (item.state == 'concept' || item.state == 'afgekeurd') && user.organisationTypeId != 2
            " rounded="circle" elevation="0" size="small" icon="mdi-square-edit-outline" color="info" class="ml-1"
              @click.stop="goToEdit(item)"><v-icon color="primary">mdi-square-edit-outline</v-icon></v-btn>
            <!-- Delete -->
            <delete-dialog v-if="rights.edit && (item.state == 'concept' || item.state == 'afgekeurd') && item.canDelete" 
            :title="deleteDialogTitle(item)" :text="deleteDialogText(item)" @confirm="(deleteItem = item), deleteItemContract();" />
            <!-- Borderel -->
            <v-btn v-if="showBorderelDetail(item)" rounded="circle" elevation="0" size="small" icon="mdi-receipt-text-edit" color="info" class="ml-1"
              @click.stop="goToBorderel(item)"><v-icon color="primary">mdi-receipt-text-edit</v-icon></v-btn>
            <!-- Watch -->
            <v-btn rounded="circle" elevation="0" size="small" icon="mdi-delete" color="warning" class="ml-1"
              @click.stop="goToDetail(item)"><v-icon color="primary">mdi-eye</v-icon></v-btn>
          </template>

        </v-data-table>

        <!-- Table settings -->
        <table-settings :items="tableContractsFiltered" :paging="tableContractsPaging"
          :export-columns="downloadExportColumns" :file-name="'dip-financiele-afspraken-export'"
          :sheet-name="'financiële afspraken'" :show-excel-button="true">
        </table-settings>

      </v-col>
    </v-row>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ this.snackbarText }}
      <template v-slot:actions>
        <v-btn color="#fff" variant="text" @click="(snackbar = false), (snackbarText = '')">
          Sluiten
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import TableSettings from '@/components/TableSettings';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';

export default {
  name: 'Contracts',
  components: {
    BreadcrumbsWithHelp,
    TableSettings,
    FilterPeriod,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'financiële afspraken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
      },
      contracts: [],
      statuses: [],
      types: [],
      tableContractsFiltered: [],
      tableContractsLoading: true,
      tableContractsHeaders: [
        { title: 'id', key: 'id' },
        { title: 'kenmerk', key: 'code' },
        { title: 'productie', key: 'production' },
        { title: 'producent', key: 'producer' },
        { title: 'afnemer', key: 'theater', showFor: [1, 3] },
        { title: 'impresariaat', key: 'agency', showFor: [2] },
        { title: 'vast bedrag', key: 'fixedAmount' },
        { title: 'percentage', key: 'percentage' },
        { title: 'datum 1e voorstelling', key: 'firstPerformance' },
        { title: 'mijn aandeel', key: 'totalExVat' },
        { title: 'laatste wijziging', key: 'modified' },
        { title: 'status', key: 'state' },
        { title: 'type afspraak', key: 'type' },
        { title: '', value: 'action', sortable: false, align: 'end', width: '170' },
      ],
      tableContractsFilter: {
        theater: '',
        producer: '',
        production: '',
        status: null,
        type: null,
      },
      tableContractsPaging: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 25,
      },
      tableContractsFilterDialog: false,
      deleteItem: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'secondary',
      downloadExportColumns: [
        { label: 'kenmerk', field: 'code' },
        { label: 'productie', field: 'production' },
        { label: 'producent', field: 'producer' },
        { label: 'afnemer', field: 'theater' },
        { label: 'vast bedrag', field: 'fixedAmount' },
        { label: 'percentage', field: 'percentage' },
        { label: 'datum 1e voorstelling', field: 'firstPerformance' },
        { label: 'mijn aandeel', field: 'totalExVat' },
        { label: 'laatste wijziging', field: 'modified' },
        { label: 'status', field: 'state' },
        { label: 'type afspraak', field: 'type' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('Contracts');

    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableContractsFilter,
    });
    this.tableContractsFilter = this.getFilter(this.$options.name);
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),
    ...mapState('auth', ['user']),

    goToDetail(row) {
      this.$router.push({
        name: 'ContractsDetail',
        params: { type: row.type.toLowerCase(), contractId: row.id },
      });
    },
    goToEdit(row) {
      this.$router.push({
        name: 'ContractsEdit',
        params: { type: row.type.toLowerCase(), contractId: row.id },
      });
    },
    goToBorderel(row) {
      this.$router.push({
        name: 'BorderelDetail',
        params: { contractId: row.id },
      });
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getContracts: function () {
      this.contracts = [];
      this.tableContractsFiltered = [];
      this.tableContractsLoading = true;

      ApiService.get('/Contracts/GetAll', {
        startDate: functions.jsDateToStringDate(this.filterPeriod.from),
        endDate: functions.jsDateToStringDate(this.filterPeriod.to),
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.contracts = response.data.result;

          this.contracts = this.contracts.map(contract => {
            return {
              ...contract,
              firstPerformanceView: functions.getDateTimeView(contract.firstPerformance),
              modifiedView: functions.getDateTimeView(contract.modified),
              fixedAmountView: functions.getEuroSignView(contract.fixedAmount).replace('.', ''),
              percentageView: contract.percentage != null ? contract.percentage + '%' : '',
              state: functions.getStatusNameNL(contract.state),
            };
          });

          this.types = this.contracts
            .map(contract => contract.type)
            .filter((type, index, self) => self.indexOf(type) === index)
            .map(type => ({
              value: type,
              title: type,
            }));

          this.statuses = this.contracts
            .map(contract => contract.state)
            .filter((state, index, self) => self.indexOf(state) === index)
            .map(state => ({
              value: state,
              title: state,
            }));

          this.tableContractsFiltered = this.contracts;

          // Paging
          this.checkFilter({
            viewName: this.$options.name + '-paging',
            filterObject: this.tableContractsPaging,
          });
          this.tableContractsPaging = this.getFilter(this.$options.name + '-paging');

          this.filterContracts();
          this.tableContractsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
    filterContracts: function () {
      const vm = this;

      this.tableContractsFiltered = this.contracts.filter(function (contract) {
        return (
          cleanWords(contract.theater).indexOf(cleanWords(vm.tableContractsFilter.theater)) !== -1 &&
          (cleanWords(contract.producer).indexOf(cleanWords(vm.tableContractsFilter.producer)) !== -1 ||
            cleanWords(contract.agency).indexOf(cleanWords(vm.tableContractsFilter.producer)) !== -1) &&
          cleanWords(contract.production).indexOf(cleanWords(vm.tableContractsFilter.production)) !== -1 &&
          cleanWords(contract.state).indexOf(cleanWords(vm.tableContractsFilter.status)) !== -1 &&
          cleanWords(contract.type).indexOf(cleanWords(vm.tableContractsFilter.type)) !== -1
        );
      });

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterContracts: function () {
      this.tableContractsFilter.theater = '';
      this.tableContractsFilter.producer = '';
      this.tableContractsFilter.production = '';
      this.tableContractsFilter.status = null;
      this.tableContractsFilter.type = null;
      this.tableContractsFilterDialog = false;
    },
    deleteItemContract: function () {
      ApiService.delete('/Contracts/Delete', {
        contractId: this.deleteItem.id,
      })
        .then((response) => {
          if (response.data.success) {
            let i = this.contracts.map((item) => item.id).indexOf(this.deleteItem.id);
            this.contracts.splice(i, 1);
            this.filterContracts();

            this.snackbarText = this.deleteItem.code + ' is verwijderd';
            this.deleteItem = '';
            this.snackbarColor = 'secondary';
            this.snackbar = true;

            this.getContracts();
          } else {
            console.log('error');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    deleteDialogTitle(value) {
      return `${value.code} verwijderen?`;
    },
    deleteDialogText(value) {
      return `Weet je zeker dat je ${value.code} wilt verwijderen? Het is daarna niet meer terug te vinden.`;
    },
    showBorderelDetail(item) {
      const firstPerformanceDate = new Date(item.firstPerformance);
      return this.user.hasBorderelAccess && item.state === 'goedgekeurd' && item.numberOfPerformances === 1 
              && item.pnlPerformanceId !== null && firstPerformanceDate < new Date();
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),
    helpId() {
      if (this.user.organisationTypeId === 1) {
        return "1111";
      } else if (this.user.organisationTypeId === 2) {
        return "1112";
      } else if (this.user.organisationTypeId === 3) {
        return "1113";
      } else {
        return null;
      }
    },
    tableContractsHeadersFiltered() {
      return this.tableContractsHeaders.filter((h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId));
    },
  },
  beforeUnmount() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableContractsFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableContractsPaging,
    });
  },
  watch: {
    tableContractsFilter: {
      handler: 'filterContracts',
      deep: true,
    },
    filterPeriod: function () {
      this.getContracts();
    },
  },
};
</script>