import { createVuetify } from 'vuetify';
import defaults from './defaults';

// Import translations for Vuetify
import nl from 'vuetify/lib/locale/nl';

// Styles
import 'vuetify/lib/styles/main.sass';

import { VImg, VAvatar, VIcon, VList, VListItem, VContainer, VBtn, VApp, VListGroup, VNavigationDrawer, VSpacer, VMenu, VAppBar, VMain, VCardTitle, VCardSubtitle, VCardText, VCardActions, VDialog, VTextField, VAlert, VForm, VCol, VCard, VRow, VLayout,  VListItemTitle, VBreadcrumbs, VBreadcrumbsItem, VTextarea, VProgressLinear, VChip, VTable, VDataTable, VPagination, VAutocomplete, VTooltip, VSnackbar, VDatePicker, VCheckbox, VSelect, VExpansionPanels, VExpansionPanel, VExpansionPanelTitle, VExpansionPanelText, VRadio, VRadioGroup, VDivider, VFileInput, VSwitch, VProgressCircular, VOtpInput, VOverlay } from 'vuetify/lib/components';

// Create Vuetify instance
const vuetify = createVuetify({
  components: {
    VImg, VAvatar, VIcon, VList, VListItem, VContainer, VBtn, VApp, VListGroup, VNavigationDrawer, VSpacer, VMenu, VAppBar, VMain, VCardTitle, VCardSubtitle, VCardText, VCardActions, VDialog, VTextField, VAlert, VForm, VCol, VCard, VRow, VLayout, VListItemTitle, VBreadcrumbs, VBreadcrumbsItem, VTextarea, VProgressLinear, VChip, VTable, VDataTable, VPagination, VAutocomplete, VTooltip, VSnackbar, VDatePicker, VCheckbox, VSelect, VExpansionPanels, VExpansionPanel, VExpansionPanelTitle, VExpansionPanelText, VRadio, VRadioGroup, VDivider, VFileInput, VSwitch, VProgressCircular, VOtpInput, VOverlay
  },
  aliases: {
    VBtnSave: VBtn,
    VBtnEdit: VBtn,
    VBtnDelete: VBtn,
    VBtnCancel: VBtn,
    VBtnFind: VBtn,
  },
  defaults,
  lang: {
    locales: { nl },
    current: 'nl',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#021e72',
          'on-primary': '#fff',
          secondary: '#0abfb8',
          'on-secondary': '#fff',
          accent: '#f4f7fc',
          accent_two: '#e1e7f2',
          error: '#d92853',
          info: '#f4f7fc',
          success: '#0abfb8',
          warning: '#fff453',
        },
      },
    },
  },
});

export default vuetify;