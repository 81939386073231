<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="50"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="flex align-center pb-2" no-gutters>
            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column" ref="form">
                <v-col cols="12">
                  <v-card title="voorstelling toevoegen">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-select v-model="performanceLocation" :items="locationOptions" label="Locatie"
                            color="primary"></v-select>
                        </v-col>
                      </v-row>
                      <div v-if="performanceLocation === 'theater'">
                        <v-row>
                          <v-col cols="12" class="py-1">
                            <v-autocomplete id="theater" label="theater" v-model="theaterId" :items="theaters"
                              :rules="formValidationRules.required"></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="py-1">
                            <v-autocomplete id="zalen" label="zalen" v-model="addProductionPerformanceForm.areaId"
                              :items="areas" :rules="formValidationRules.required"></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <v-checkbox color="primary" label="In het kader van een festival?" name="festival"
                              v-model="addProductionPerformanceForm.festival"></v-checkbox>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else class="py-5">
                        <v-row>
                          <v-col cols="12" class="py-1">
                            <v-autocomplete id="country" label="land"
                              v-model="addProductionPerformanceForm.addArea.countryId" :items="countries" clearable
                              :rules="formValidationRules.required"></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" class="py-1">
                            <v-text-field label="naam locatie" v-model="addProductionPerformanceForm.addArea.name"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <!-- Straat -->
                          <v-col cols="8" class="py-1">
                            <v-text-field label="straat" v-model="addProductionPerformanceForm.addArea.street"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>

                          <v-col cols="4" class="py-1">
                            <!-- Nummer -->
                            <v-text-field label="huisnummer" v-model="addProductionPerformanceForm.addArea.number"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="py-1">
                            <!-- Postcode -->
                            <v-text-field label="postcode" v-model="addProductionPerformanceForm.addArea.zipCode"
                              :rules="zipCodeValidationType"></v-text-field>
                          </v-col>
                          <v-col cols="8" class="py-1">
                            <!-- Plaats -->
                            <v-text-field label="plaats" v-model="addProductionPerformanceForm.addArea.city"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>

                        <!-- Provincie -->
                        <v-row v-if="showProvince">
                          <v-col cols="12" class="py-1">
                            <v-select label="provincie" v-model="addProductionPerformanceForm.addArea.provinceId"
                              :items="provincieOptions" :rules="formValidationRules.required"></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4">
                            <v-text-field v-model="addProductionPerformanceForm.capacity"
                              label="capaciteit"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field v-model="addProductionPerformanceForm.ticketCount"
                              label="aantal verkochte tickets"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field v-model="addProductionPerformanceForm.revenueInVat" label="recette"
                              prefix="€"></v-text-field>
                          </v-col>
                        </v-row>

                      </div>
                      <v-row v-if="performanceLocation !== 'theater'">
                        <v-divider class="py-3"></v-divider>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <date-picker :passed-date="addProductionPerformanceForm.startDate" rules="dateRequired"
                            @formatedDate="addProductionPerformanceForm.startDate = $event"></date-picker>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field placeholder="UU:MM" v-model="addProductionPerformanceForm.startDateTime"
                            :rules="formValidationRules.timeRequired" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete id="status" label="Status" v-model="addProductionPerformanceForm.state"
                            :items="performanceStatus" :rules="formValidationRules.required"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete id="type_voorstelling" label="Type voorstelling"
                            v-model="addProductionPerformanceForm.type" :items="performanceType"
                            :rules="formValidationRules.required"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <v-checkbox color="primary" label="besloten voorstelling" name="private"
                            v-model="addProductionPerformanceForm.private"></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete id="kenmerk_voorstelling" multiple label="Kenmerk voorstelling"
                            v-model="addProductionPerformanceForm.characteristicIds"
                            :items="characteristicsOptions"></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div>
                        <v-btn-save @click="hasPerformanceDuplicatesCheck" :loading="formLoading">Opslaan</v-btn-save>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="duplicatePerformanceDialog" max-width="500">
        <v-card prepend-icon="mdi-information" :text="duplicatePerformanceDialogMessage" title="dubbele voorstelling">
          <template v-slot:actions>
            <v-btn color="error" variant="elevated"
              @click="(duplicatePerformanceDialog = false), (formLoading = false);">Nee</v-btn>
            <v-btn color="primary" variant="elevated" @click="submit">Ja</v-btn>
          </template>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import DatePicker from '../../../components/DatePicker.vue';

export default {
  name: 'LibraryProductionsPerformanceAdd',
  props: ['productionNumber'],
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      production: {
        name: '',
      },
      isEditing: false,
      formError: '',
      formLoading: false,
      addProductionPerformanceForm: {
        seasonId: null,
        theaterId: null,
        areaId: null,
        templateId: null,
        characteristicIds: [],
        contractId: null,
        startDate: '',
        startDateTime: '',
        dateTime: '',
        type: null,
        festival: false,
        private: false,
        state: null,
        ticketCount: 0,
        addArea: {
          name: '',
          sameAdres: false,
          street: '',
          number: '',
          zipCode: '',
          city: '',
          provinceId: null,
          countryId: 150, // Netherlands
        },
      },
      theaters: [],
      areas: [],
      countries: [],
      provincieOptions: [],
      performanceStatus: [
        { title: 'interesse', value: 'interest' },
        { title: 'optie', value: 'option' },
        { title: 'geaccepteerd', value: 'agreed' },
        { title: 'succesoptie', value: 'success' },
        { title: 'annulering', value: 'cancellation' },
      ],
      performanceType: [
        { title: 'normaal', value: 'normal' },
        { title: 'tryout', value: 'tryout' },
        { title: 'premiere', value: 'premiere' },
        { title: 'montage', value: 'montage' },
        { title: 'derniere', value: 'derniere' },
      ],
      characteristicsOptions: [],
      formValidationRules: {},
      duplicatePerformanceDialog: false,
      duplicatePerformanceDialogMessage: '',
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstelling toevoegen',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      performanceLocation: 'theater', // Default selection
      locationOptions: [
        { title: 'in een theater', value: 'theater' },
        { title: 'andere locatie', value: 'other' },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsPerformanceAdd');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getOrganisations();
    this.getCharacteristics();
    this.getCountries();
    this.getProvinces();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;
            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          //router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          response.data.result.forEach((element) => {
            this.theaters.push({
              value: element.id,
              title: `${element.name} (${element.city}, ${element.id})`,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getAreas: function () {
      this.areas = [];
      ApiService.get('/TheaterAreas/GetAll', {
        theaterId: this.addProductionPerformanceForm.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.areas.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getCountries: function () {
      this.countries = [];
      ApiService.get('/Country/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.countries.push({ value: element.id, title: element.nicename + ' (' + element.iso + ')' });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    getCharacteristics: function () {
      ApiService.get('/Characteristics/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.characteristicsOptions.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    async hasPerformanceDuplicatesCheck() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addProductionPerformanceForm;

        // combine startDate and startDateTime
        let combinedDate =
          this.addProductionPerformanceForm.startDate + ' ' + this.addProductionPerformanceForm.startDateTime;

        ApiService.get('/Performances/GetDuplicate', {
          theaterId: form.theaterId,
          areaId: form.areaId,
          startDate: functions.getDateTimeDatabase(combinedDate),
        }).then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success && response.data.result.isDuplicate) {
            this.duplicatePerformanceDialog = true;

            var duplicatePerformanceOrganisation =
              response.data.result.performance.producerName == ''
                ? response.data.result.performance.agencyName
                : response.data.result.performance.producerName;
            var duplicateMessage = `Let op! Op ${functions.getDateTimeView(
              response.data.result.performance.startDate
            )} in ${response.data.result.performance.theaterName} ${response.data.result.performance.areaName} staat ${response.data.result.performance.number
              } van ${duplicatePerformanceOrganisation} met de status ${functions.changeState(
                response.data.result.performance.state
              )} al geboekt. Weet je zeker dat deze boeking correct is?`;

            this.duplicatePerformanceDialogMessage = duplicateMessage;
          } else if (response.data.success && !response.data.result.isDuplicate) {
            this.submit();
          } else {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          }
        });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        const form = this.addProductionPerformanceForm;

        // combine startDate and startDateTime
        let combinedDate =
          this.addProductionPerformanceForm.startDate + ' ' + this.addProductionPerformanceForm.startDateTime;

        ApiService.post('/Performances/Add', {
          productionNumber: this.productionNumber,
          theaterId: this.otherPerformance ? -1 : form.theaterId,
          areaId: this.otherPerformance ? -1 : form.areaId,
          characteristicIds: form.characteristicIds,
          startDate: functions.getDateTimeDatabase(combinedDate),
          type: form.type,
          festival: form.festival,
          private: form.private,
          state: form.state,
          isOtherPerformance: this.otherPerformance,
          theaterArea: this.otherPerformance ? form.addArea : null,
          capacity: form.capacity,
          ticketCount: form.ticketCount,
          revenueInVat: form.revenueInVat,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch((error) => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            console.log(error);
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    theaterId: {
      get() {
        return this.addProductionPerformanceForm.theaterId;
      },
      set(value) {
        this.addProductionPerformanceForm.theaterId = value;
        this.getAreas();
      },
    },
    showProvince() {
      return this.addProductionPerformanceForm.addArea.countryId === 150;
    },
    otherPerformance() {
      return this.performanceLocation === 'other';
    },
    zipCodeValidationType() {
      return this.addProductionPerformanceForm.addArea.countryId === 150 ? this.formValidationRules.zipCodeRequired : this.formValidationRules.required;
    }
  },
  watch: {
    addProductionPerformanceForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
