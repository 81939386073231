<template>
  <div>
    <v-row>
      <v-col cols="12">
        <!-- Breadcrumbs and help -->
        <breadcrumbs-with-help :breadcrumbs="breadcrumbs" help-id="51"></breadcrumbs-with-help>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Page info first row -->
          <v-row class="d-flex" justify="center" align="center" no-gutters>
            <v-col cols="12" class="py-0">
              <!-- Form -->
              <v-form @submit.prevent class="d-flex flex-column" ref="form">
                <v-col cols="12">
                  <v-card title="voorstelling bewerken">
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-select v-model="performanceLocation" :items="locationOptions" label="Locatie"
                            color="primary" disabled></v-select>
                        </v-col>
                      </v-row>
                      <div v-if="performanceLocation === 'theater'">
                        <v-row>
                          <v-col cols="12" class="py-1">
                            <div v-if="editProductionPerformanceForm.hasContract">
                              <v-tooltip bottom max-width="500" color="primary">
                                <template v-slot:activator="{ props: activatorProps }">
                                  <div v-bind="activatorProps">
                                    <v-autocomplete label="theater" v-model="editProductionPerformanceForm.theaterId"
                                      :items="theaters" disabled hide-details="auto" class="mb-5">
                                    </v-autocomplete>
                                  </div>
                                </template>
                                <span> er is al een financiële afspraak aangemaakt voor deze voorstelling </span>
                              </v-tooltip>
                            </div>
                            <div v-else>
                              <v-autocomplete label="theater" @update:modelValue="getAreas"
                                v-model="editProductionPerformanceForm.theaterId" :items="theaters"
                                :rules="formValidationRules.required"
                                :disabled="editProductionPerformanceForm.hasContract"></v-autocomplete>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="py-1">
                            <v-autocomplete label="zalen" v-model="editProductionPerformanceForm.areaId" :items="areas"
                              :rules="formValidationRules.required"></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <v-checkbox color="primary" label="in het kader van een festival?" name="festival"
                              v-model="editProductionPerformanceForm.festival"></v-checkbox>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else class="py-5">
                        <v-row>
                          <v-col cols="12" class="py-1">
                            <v-autocomplete id="country" label="land"
                              v-model="editProductionPerformanceForm.editArea.countryId" :items="countries" clearable
                              :rules="formValidationRules.required"></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" class="py-1">
                            <v-text-field label="naam locatie" v-model="editProductionPerformanceForm.editArea.name"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <!-- Straat -->
                          <v-col cols="8" class="py-1">
                            <v-text-field label="straat" v-model="editProductionPerformanceForm.editArea.street"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>

                          <v-col cols="4" class="py-1">
                            <!-- Nummer -->
                            <v-text-field label="huisnummer" v-model="editProductionPerformanceForm.editArea.number"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4" class="py-1">
                            <!-- Postcode -->
                            <v-text-field label="postcode" v-model="editProductionPerformanceForm.editArea.zipCode"
                              :rules="zipCodeValidationType"></v-text-field>
                          </v-col>
                          <v-col cols="8" class="py-1">
                            <!-- Plaats -->
                            <v-text-field label="plaats" v-model="editProductionPerformanceForm.editArea.city"
                              :rules="formValidationRules.required"></v-text-field>
                          </v-col>
                        </v-row>

                        <!-- Provincie -->
                        <v-row v-if="showProvince">
                          <v-col cols="12" class="py-1">
                            <v-select label="provincie" v-model="editProductionPerformanceForm.editArea.provinceId"
                              :items="provincieOptions" :rules="formValidationRules.required"></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="4">
                            <v-text-field v-model="editProductionPerformanceForm.capacity"
                              label="capaciteit"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field v-model="editProductionPerformanceForm.ticketCount"
                              label="aantal verkochte tickets"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field v-model="editProductionPerformanceForm.revenueInVat" label="recette"
                              prefix="€"></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row v-if="otherPerformance">
                        <v-divider class="py-3"></v-divider>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <date-picker v-if="datePickerDataLoaded"
                            :passed-date="editProductionPerformanceForm.startDate" rules="dateTimeRequired"
                            @formatedDate="editProductionPerformanceForm.startDate = $event"></date-picker>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-text-field placeholder="UU:MM" v-model="editProductionPerformanceForm.startDateTime"
                            :rules="formValidationRules.timeRequired"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete label="status" v-model="editProductionPerformanceForm.state"
                            :items="performanceStatus" :rules="formValidationRules.required"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete label="type voorstelling" v-model="editProductionPerformanceForm.type"
                            :items="performanceType" :rules="formValidationRules.required"></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <v-checkbox color="primary" label="besloten voorstelling" name="private"
                            v-model="editProductionPerformanceForm.private"></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <v-autocomplete multiple label="kenmerk voorstelling "
                            v-model="editProductionPerformanceForm.characteristicIds"
                            :items="characteristicsOptions"></v-autocomplete>
                        </v-col>
                      </v-row>

                      <v-alert type="error" variant="tonal" v-if="formError != ''">{{ formError }}</v-alert>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <div>
                        <v-btn-save @click="submit" :loading="formLoading">Opslaan</v-btn-save>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../../../services/api.service';
import router from '../../../router/router.js';
import functions from '../../../services/functions.service';
import { TokenService } from '../../../services/storage.service';
import BreadcrumbsWithHelp from '@/components/BreadcrumbsWithHelp.vue';
import DatePicker from '../../../components/DatePicker';

export default {
  name: 'LibraryProductionsPerformanceEdit',
  props: ['productionNumber', 'performanceId'],
  components: {
    BreadcrumbsWithHelp,
    DatePicker,
  },
  data() {
    return {
      production: {
        name: '',
      },
      isEditing: false,
      formError: '',
      formLoading: false,
      datePickerDataLoaded: false,
      editProductionPerformanceForm: {
        seasonId: null,
        theaterId: null,
        areaId: null,
        characteristicIds: [],
        contractId: null,
        startDate: new Date().toISOString().substring(0, 10),
        startDateTime: '',
        dateTime: '',
        type: '',
        festival: false,
        private: false,
        state: null,
        hasContract: false,
        otherPerformance: false,
        ticketCount: 0,
        editArea: {
          name: '',
          sameAdres: false,
          street: '',
          number: '',
          zipCode: '',
          city: '',
          provinceId: null,
          countryId: 150, // Netherlands
        },
      },
      performanceLocation: 'theater', // Default selection
      locationOptions: [
        { title: 'in een theater', value: 'theater' },
        { title: 'andere locatie', value: 'other' },
      ],
      theaters: [],
      areas: [],
      countries: [],
      provincieOptions: [],
      performanceStatus: [
        { title: 'interesse', value: 'interest' },
        { title: 'optie', value: 'option' },
        { title: 'geaccepteerd', value: 'agreed' },
        { title: 'succesoptie', value: 'success' },
        { title: 'annulering', value: 'cancellation' },
      ],
      performanceType: [
        { title: 'normaal', value: 'normal' },
        { title: 'tryout', value: 'tryout' },
        { title: 'premiere', value: 'premiere' },
        { title: 'montage', value: 'montage' },
        { title: 'derniere', value: 'derniere' },
      ],
      characteristicsOptions: [],
      formValidationRules: {},
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'beheer',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'producties',
          disabled: false,
          href: '/beheer/producties',
          bold: false,
        },
        {
          text: '',
          disabled: false,
          href: '',
          bold: false,
        },
        {
          text: 'voorstelling bewerken',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventNav);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (!window.confirm('Pagina verlaten zonder op te slaan?')) {
        return;
      }
    }
    next();
  },
  mounted() {
    this.rights = this.getComponentRights('LibraryProductionsPerformanceEdit');
    this.checkRights();
    this.formValidationRules = functions.getFormValidationRules();
    this.getProduction();
    this.getOrganisations();
    this.getPerformance();
    this.getCharacteristics();
    this.getCountries();
    this.getProvinces();
  },
  methods: {
    preventNav(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = '';
    },
    checkRights() {
      if (!this.rights.edit) {
        router.back();
      }
    },
    splitDateTime: function (date) {
      if (date == null || date == '') {
        return '';
      }
      var dateArray = date.split('T');

      this.editProductionPerformanceForm.startDate = dateArray[0].split('-').reverse().join('-');
      this.editProductionPerformanceForm.startDateTime = dateArray[1].slice(0, 5);

      this.datePickerDataLoaded = true;
    },
    getProduction: function () {
      ApiService.get('/Productions/Get', {
        productionNumber: this.productionNumber,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const production = response.data.result;

            this.breadcrumbs[2].text = production.title;
            this.breadcrumbs[2].href = '/beheer/producties/' + this.productionNumber + '/';
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getOrganisations: function () {
      ApiService.get('/Theaters/GetAllDropDown')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.theaters.push({
              value: element.id,
              title: `${element.name} (${element.city}, ${element.id})`,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getAreas: function () {
      ApiService.get('/TheaterAreas/GetAll', {
        theaterId: this.editProductionPerformanceForm.theaterId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          this.areas = response.data.result.map((element) => ({
            value: element.id,
            title: element.name,
          }));
          if (!this.areas.map((a) => a.value).includes(this.editProductionPerformanceForm.areaId)) {
            this.editProductionPerformanceForm.areaId = '';
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getPerformance: function () {
      const vm = this;

      ApiService.get('/Performances/Get', {
        performanceId: this.performanceId,
      })
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          if (response.data.success) {
            const link = response.data.result;

            this.editProductionPerformanceForm.seasonId = link.seasonId;
            this.editProductionPerformanceForm.theaterId = link.theaterId;
            this.editProductionPerformanceForm.areaId = link.areaId;
            this.getAreas();
            this.editProductionPerformanceForm.contractId = link.contractId;
            this.splitDateTime(link.startDate);
            this.editProductionPerformanceForm.type = link.type;
            this.editProductionPerformanceForm.festival = link.isFestival;
            this.editProductionPerformanceForm.private = link.isPrivate;
            this.editProductionPerformanceForm.state = link.state;
            this.editProductionPerformanceForm.hasContract = link.hasContract;
            this.editProductionPerformanceForm.otherPerformance = link.isOtherPerformance;
            this.editProductionPerformanceForm.editArea = link.theaterArea;
            this.editProductionPerformanceForm.capacity = link.capacity;
            this.editProductionPerformanceForm.ticketCount = link.ticketCount;
            this.editProductionPerformanceForm.revenueInVat = link.revenueInVat;

            this.performanceLocation = link.isOtherPerformance ? 'other' : 'theater';

            var characteristicIds = [];
            for (var i = 0; i < link.characteristics.length; i++) {
              characteristicIds.push(link.characteristics[i].id);
            }

            this.editProductionPerformanceForm.characteristicIds = characteristicIds;

            setTimeout(function () {
              vm.isEditing = false;
            }, 200);
          } else {
            router.back();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
          router.back();
        });
    },
    getCharacteristics: function () {
      ApiService.get('/Characteristics/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.characteristicsOptions.push({
              value: element.id,
              title: element.name,
            });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getCountries: function () {
      this.countries = [];
      ApiService.get('/Country/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.countries.push({ value: element.id, title: element.nicename + ' (' + element.iso + ')' });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getProvinces: function () {
      ApiService.get('/Provinces/GetAll')
        .then((response) => {
          TokenService.saveToken(response.data.token);
          ApiService.setHeader();
          response.data.result.forEach((element) => {
            this.provincieOptions.push({ value: element.id, title: element.name });
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.errored = true;
        });
    },
    async submit() {
      this.formError = '';
      this.formLoading = true;
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        const form = this.editProductionPerformanceForm;

        // combine startDate and startDateTime
        let combinedDate =
          this.editProductionPerformanceForm.startDate + ' ' + this.editProductionPerformanceForm.startDateTime;

        ApiService.put('/Performances/Update', {
          id: parseInt(this.performanceId),
          theaterId: this.editProductionPerformanceForm.otherPerformance ? -1 : form.theaterId,
          areaId: this.editProductionPerformanceForm.otherPerformance ? -1 : form.areaId,
          characteristicIds: form.characteristicIds,
          startDate: functions.getDateTimeDatabase(combinedDate),
          type: form.type,
          festival: form.festival,
          private: form.private,
          state: form.state,
          isOtherPerformance: this.editProductionPerformanceForm.otherPerformance,
          theaterArea: this.editProductionPerformanceForm.otherPerformance ? form.editArea : null,
          capacity: form.capacity,
          ticketCount: form.ticketCount,
          revenueInVat: form.revenueInVat,
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            if (response.data.success) {
              this.isEditing = false;
              router.back();
            } else {
              this.formError =
                'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
              this.formLoading = false;
            }
          })
          // eslint-disable-next-line
          .catch(() => {
            this.formError =
              'Helaas, er is iets mis gegaan. Probeer het opnieuw. Blijft het misgaan, neem dan contact op via de supportknop op het dashboard.';
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
        this.$refs.form.validate();
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    showProvince() {
      return this.editProductionPerformanceForm.editArea.countryId === 150;
    },
    otherPerformance() {
      return this.performanceLocation === 'other';
    },
    zipCodeValidationType() {
      return this.editProductionPerformanceForm.editArea.countryId === 150 ? this.formValidationRules.zipCodeRequired : this.formValidationRules.required;
    }
  },
  watch: {
    editProductionPerformanceForm: {
      deep: true,
      handler() {
        this.isEditing = true;
      },
    },
  },
};
</script>

<style>
.input-sm {
  min-width: 10rem;
}

.checkbox label {
  font-size: 0.9rem;
  font-weight: 700;
}
</style>
